.footer {
  margin-top: rem(120px);
  padding-top: calc(var(--mantine-spacing-xl) * 2);
  padding-bottom: calc(var(--mantine-spacing-xl) * 2);
}

.wrapper {
  @media (max-width: 50em) {
    width: auto;
  }
}

.inner {
  width: 100%;
  display: flex;
  justify-content: flex-start;

  @media (max-width: 50em) {
    width: auto;
  }
}

.link {
  cursor: pointer;
  display: block;
  color: light-dark(var(--mantine-color-gray-6), var(--mantine-color-dark-1));
  font-size: var(--mantine-font-size-sm);
  padding-top: rem(3px);
  padding-bottom: rem(3px);
}

.nonLink {
  display: block;
}

.nonLink:hover {
  text-decoration: none;
  cursor: default;
}

.title {
  font-size: var(--mantine-font-size-lg);
  font-weight: 700;
  font-family: Greycliff CF, var(--mantine-font-family);
  margin-bottom: calc(var(--mantine-spacing-xs) / 2);
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
}

.afterFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--mantine-spacing-xl);
  padding-top: var(--mantine-spacing-xl);
  border-top: rem(1px) solid
    light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-4));

  @media (max-width: $mantine-breakpoint-sm) {
    flex-direction: column;
  }
}

.social {
  @media (max-width: $mantine-breakpoint-sm) {
    margin-top: var(--mantine-spacing-xs);
  }
}

.card {
  background-color: var(--mantine-color-body);
  min-width: 300px;
}

.avatar {
  border: rem(2px) solid var(--mantine-color-body);
}

.button {
  margin-top: 1rem;
}
